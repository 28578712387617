/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
const BioGrid = styled.div`
  display: flex;
  gap: 2rem;
  flex-direction: row;
  grid-template-rows: 1fr;
  justify-content: center;
  font-size: var(--heading-1);
  .grid-item {
    /* display: inline-block; */
    /* align-items: center; */
    /* justify-content: center; */
  }
  @media (max-width: 850px) {
    font-size: var(--heading-2);
    gap: 1rem;
  }
  @media (max-width: 600px) {
    flex-direction: column;
    text-align: center;
    gap: 1rem;
    .grid-item {
      justify-content: center;
    }
    p {
      margin: 0;
    }
  }
`

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      avatar: file(
        absolutePath: { regex: "./src/assets/images/profile-pic.jpg/" }
      ) {
        childImageSharp {
          fixed(width: 50, height: 50, quality: 95) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      site {
        siteMetadata {
          author {
            name
            summary
          }
          social {
            twitter
          }
        }
      }
    }
  `)

  // Set these values by editing "siteMetadata" in gatsby-config.js
  const author = data.site.siteMetadata?.author
  const social = data.site.siteMetadata?.social

  const avatar = data?.avatar?.childImageSharp?.fixed
  return (
    <BioGrid role="banner">
      <div className="grid-item">
        {avatar && (
          <Img
            fixed={avatar}
            alt={author?.name || ``}
            className="bio-avatar grid-item"
            imgStyle={{
              borderRadius: `50%`,
            }}
          />
        )}
      </div>
      <div className="grid-item">
        {author?.name && (
          <p>
            Written by <strong>{author.name}</strong> {author?.summary || null}
            {/* {` `} */}
            {/* <a href={`https://twitter.com/${social?.twitter || ``}`}>
              You should follow them on Twitter
            </a> */}
          </p>
        )}
      </div>
    </BioGrid>
  )
}

export default Bio
